<template>
  <div v-if="errorReservaGuardada">
    Error al guardar la reserva por favor intente nuevamente mas tarde.
  </div>

  <div
    v-if="!errorReservaGuardada"
    class="page d-flex flex-row flex-column-fluid"
  >
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <div class="card mb-5 mb-xxl-8 custom-card h-100 px-5">
        <div class="card-body pt-9 pb-0">
          <div class="row">
            <div
              class="
                notice
                d-flex
                bg-light-secondary
                rounded
                border-gray border border-dashed
                mb-9
                p-6
              "
            >
              <span class="me-4">
                <img
                  src="media/icons/duotone/Code/Warning-1-circle.png"
                  alt="image"
                />
              </span>

              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-6 text-gray-600">
                    Por favor ingresa los siguientes datos.
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>

          <div class="row">
            <!--Nombre-->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Nombre</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Nombre de quien reserva"
                ></i>
              </label>
              <input
                type="text"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                placeholder="Nombre"
                v-model="formulario.nombre"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.nombre.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin nombre-->

            <!--apellido-->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Apellido</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Apellido de quien reserva "
                ></i>
              </label>
              <input
                type="text"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                placeholder="Apellido"
                v-model="formulario.apellido"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.apellido.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin apellido-->
          </div>

          <div class="row">
            <!--genero-->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Genero</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Seleccione un genero"
                ></i>
              </label>
              <select
                name="servicio"
                class="form-select form-select-solid form-select-lg fw-bold"
                v-model="formulario.genero"
              >
                <option
                  :value="genero"
                  v-for="genero in generos"
                  :key="genero.id"
                >
                  {{ genero.genero }}
                </option>
              </select>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <!-- error message -->
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <div
                        class="input-errors"
                        v-for="(error, index) of v$.genero.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- fin error -->
                </div>
              </div>
            </div>
            <!--fin genero-->

            <!--telefono-->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Télefono</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Teléfono de contacto "
                ></i>
              </label>
              <input
                type="text"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                placeholder="Teléfono"
                v-model="formulario.telefono"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.telefono.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin telefono-->
          </div>

          <div class="row">
            <!--tipos de identificacion-->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Tipo de Identificación</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Seleccione tipo de identificación"
                ></i>
              </label>
              <select
                name="servicio"
                class="form-select form-select-solid form-select-lg fw-bold"
                v-model="formulario.tipoIdentificacion"
              >
                <option
                  :value="tipo"
                  v-for="tipo in tiposIdentificacion"
                  :key="tipo.id"
                >
                  {{ tipo.tipo }}
                </option>
              </select>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <!-- error message -->
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <div
                        class="input-errors"
                        v-for="(error, index) of v$.tipoIdentificacion.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- fin error -->
                </div>
              </div>
            </div>
            <!--fin tipos de identificacion--->

            <!--numero de identificacion-->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Número de identificación</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Número de identificación"
                ></i>
              </label>
              <input
                type="text"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                placeholder="Número de Indentificación"
                v-model="formulario.numeroIdentificacion"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.numeroIdentificacion.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--numero de identificacion-->
          </div>

          <div class="row">
            <!--email-->
            <div class="col-lg-12 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Email</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Email"
                ></i>
              </label>
              <input
                type="text"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                placeholder="Email"
                v-model="formulario.email"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.email.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--email-->
          </div>
          <div class="row pt-5">
            <div
              class="
                notice
                d-flex
                bg-light-primary
                rounded
                border-grey border border-dashed
                mb-9
                p-6
              "
            >
              <span class="me-4">
                <img
                  src="media/icons/duotone/Code/Warning-1-circle.png"
                  alt="image"
                />
              </span>

              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-6 text-gray-600">
                    Fecha de reserva: {{ disponibilidad.fecha }}
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>

          <div class="col-lg-6 fv-row">
            <button @click="guardar" class="btn btn-success btn-lg">
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { computed, reactive, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props: any) {
    const { id } = toRefs(props);

    const store = useStore();

    const router = useRouter();

    const errorReservaGuardada = computed(() => {
      return store.getters["reservar/errorReservaGuardada"];
    }); //obtener el error del proceso de guardado de reserva

    const disponibilidad = computed(
      () => store.getters["disponibilidad/getterDisponibilidadSeleccionada"]
    ); //disponibilidad seleccionada

    const tiposIdentificacion = computed(
      () => store.getters["tiposIdentificacion/getterTiposIdentificacion"]
    ); // tipos de identificacion

    const generos = computed(() => store.getters["generos/getterGeneros"]); // generos

    const reserva = computed(() => store.getters["reserva/getterReserva"]); //reserva

    const rules = computed(() => {
      return {
        nombre: {
          required: helpers.withMessage("Nombre es requerido", required),
        },

        apellido: {
          required: helpers.withMessage("Apellido es requerido", required),
        },

        tipoIdentificacion: {
          required: helpers.withMessage(
            "Tipo indentificación es requerido",
            required
          ),
        },

        numeroIdentificacion: {
          required: helpers.withMessage(
            "Número indentificación es requerido",
            required
          ),
        },

        genero: {
          required: helpers.withMessage("Genero es requerido", required),
        },

        telefono: {
          required: helpers.withMessage("Teléfono es requerido", required),
        },

        email: {
          required: helpers.withMessage("Email es requerido", required),
          email: helpers.withMessage("Email no valido", email),
        },
      };
    });

    const progresoReserva = computed(
      () => store.getters["reserva/getterProgresoReserva"]
    );

    const formulario = reactive({
      nombre: null,
      apellido: null,
      tipoIdentificacion: {},
      numeroIdentificacion: null,
      genero: {},
      telefono: null,
      email: null,
    }); //formulario

    const guardar = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        Swal.fire({
          html:
            ' <p>Por favor confirmar los siguientes datos:</p> <br/> <div style="text-align: left;" ><b> Nombre: </b> ' +
            formulario.nombre +
            " " +
            formulario.apellido +
            "<br/> <b>Genero: </b>" +
            JSON.parse(JSON.stringify(formulario.genero)).genero +
            "<br/> <b>Tipo de identificación: </b>" +
            JSON.parse(JSON.stringify(formulario.tipoIdentificacion)).tipo +
            "<br/> <b>Número de identificación: </b>" +
            formulario.numeroIdentificacion +
            "<br/> <b>Teléfono: </b>" +
            formulario.telefono +
            "<br/> <b>Email: </b>" +
            formulario.email +
            "</div>",
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si estan correctos",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const reserActualizada = {
              ...reserva.value,
              nombre: formulario.nombre,
              apellido: formulario.apellido,
              tipoIdentificacion: formulario.tipoIdentificacion,
              identificacion: formulario.numeroIdentificacion,
              genero: formulario.genero,
              telefono: formulario.telefono,
              email: formulario.email,
              estado: "CREADA",
            };

            store.dispatch("reserva/actualizarReserva", reserActualizada);
            router.push({
              name: "finalizar-reserva",
              params: { id: id.value },
            });
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    };

    const v$ = useVuelidate(rules, formulario);

    onMounted(() => {
      store.dispatch("generos/consultarGeneros"); //accion consultar generos
      store.dispatch("tiposIdentificacion/consultarTiposIdentificacion"); // accion consultar tipos de identificacion

      if (Number(progresoReserva.value) >= 3) {
        router.push({ name: "informacion-inicial", params: { id: id.value } });
      }
    });

    return {
      disponibilidad,
      errorReservaGuardada,
      formulario,
      generos,
      guardar,
      progresoReserva,
      reserva,
      tiposIdentificacion,
      v$,
    };
  },
};
</script>

<style  scoped>
</style>