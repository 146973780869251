
import { computed, reactive, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props: any) {
    const { id } = toRefs(props);

    const store = useStore();

    const router = useRouter();

    const errorReservaGuardada = computed(() => {
      return store.getters["reservar/errorReservaGuardada"];
    }); //obtener el error del proceso de guardado de reserva

    const disponibilidad = computed(
      () => store.getters["disponibilidad/getterDisponibilidadSeleccionada"]
    ); //disponibilidad seleccionada

    const tiposIdentificacion = computed(
      () => store.getters["tiposIdentificacion/getterTiposIdentificacion"]
    ); // tipos de identificacion

    const generos = computed(() => store.getters["generos/getterGeneros"]); // generos

    const reserva = computed(() => store.getters["reserva/getterReserva"]); //reserva

    const rules = computed(() => {
      return {
        nombre: {
          required: helpers.withMessage("Nombre es requerido", required),
        },

        apellido: {
          required: helpers.withMessage("Apellido es requerido", required),
        },

        tipoIdentificacion: {
          required: helpers.withMessage(
            "Tipo indentificación es requerido",
            required
          ),
        },

        numeroIdentificacion: {
          required: helpers.withMessage(
            "Número indentificación es requerido",
            required
          ),
        },

        genero: {
          required: helpers.withMessage("Genero es requerido", required),
        },

        telefono: {
          required: helpers.withMessage("Teléfono es requerido", required),
        },

        email: {
          required: helpers.withMessage("Email es requerido", required),
          email: helpers.withMessage("Email no valido", email),
        },
      };
    });

    const progresoReserva = computed(
      () => store.getters["reserva/getterProgresoReserva"]
    );

    const formulario = reactive({
      nombre: null,
      apellido: null,
      tipoIdentificacion: {},
      numeroIdentificacion: null,
      genero: {},
      telefono: null,
      email: null,
    }); //formulario

    const guardar = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        Swal.fire({
          html:
            ' <p>Por favor confirmar los siguientes datos:</p> <br/> <div style="text-align: left;" ><b> Nombre: </b> ' +
            formulario.nombre +
            " " +
            formulario.apellido +
            "<br/> <b>Genero: </b>" +
            JSON.parse(JSON.stringify(formulario.genero)).genero +
            "<br/> <b>Tipo de identificación: </b>" +
            JSON.parse(JSON.stringify(formulario.tipoIdentificacion)).tipo +
            "<br/> <b>Número de identificación: </b>" +
            formulario.numeroIdentificacion +
            "<br/> <b>Teléfono: </b>" +
            formulario.telefono +
            "<br/> <b>Email: </b>" +
            formulario.email +
            "</div>",
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si estan correctos",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const reserActualizada = {
              ...reserva.value,
              nombre: formulario.nombre,
              apellido: formulario.apellido,
              tipoIdentificacion: formulario.tipoIdentificacion,
              identificacion: formulario.numeroIdentificacion,
              genero: formulario.genero,
              telefono: formulario.telefono,
              email: formulario.email,
              estado: "CREADA",
            };

            store.dispatch("reserva/actualizarReserva", reserActualizada);
            router.push({
              name: "finalizar-reserva",
              params: { id: id.value },
            });
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    };

    const v$ = useVuelidate(rules, formulario);

    onMounted(() => {
      store.dispatch("generos/consultarGeneros"); //accion consultar generos
      store.dispatch("tiposIdentificacion/consultarTiposIdentificacion"); // accion consultar tipos de identificacion

      if (Number(progresoReserva.value) >= 3) {
        router.push({ name: "informacion-inicial", params: { id: id.value } });
      }
    });

    return {
      disponibilidad,
      errorReservaGuardada,
      formulario,
      generos,
      guardar,
      progresoReserva,
      reserva,
      tiposIdentificacion,
      v$,
    };
  },
};
